
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import SimpleDataTable from '@/components/shared/SimpleDataTable.vue'
import { formattersMap } from '@/lib/Utils'
import { SummaryRow } from '@/presentation/remittance/model/SummaryRow'
import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'
import { Remittance } from '@/presentation/remittance/model/Remittance'
import { Decimal } from 'decimal.js'
import { KrwFxRates } from '@/gateway/fxRates/KrwFxRates'
import {
  IRegisterRemittancePresentation,
  RegisterRemittancePresentation
} from '@/presentation/remittance/RegisterRemittancePresentation'
import { CreateRemittanceGroupResponseEnum } from '@/usecase/remittance/model/CreateRemittanceGroupResponseEnum'
import { Utils } from '@/static/Utils'

@Component({
  components: { SimpleDataTable, BasicDialog }
})
export default class Register extends Vue {
  @Mutation setCreatedRemittance: any
  @Mutation setManualValidatedTransfers: any
  @Mutation setValidatedTransfers: any
  @Mutation setAnalogueRemittanceGroups: any
  @Mutation setAnalogueConfirmedRemittanceGroups: any
  @Getter getAnalogueConfirmedRemittanceGroups: any
  @Getter getManualValidatedTransfers: any
  @Getter getValidatedTransfers: any
  @Getter getRegisterTransfers: any

  presentation: IRegisterRemittancePresentation = new RegisterRemittancePresentation()
  krwRates!: KrwFxRates
  ratesDialog: boolean = false
  ratesTime: string = ''
  tableData!: Dictionary<Array<Remittance | SummaryRow>>
  hasTableData: boolean = false
  disableSummaryProps: Array<string> = ['corp_pid', 'receive_amount']
  hideColumnProps: Array<string> = ['id', 'base_amount_currency', 'send_amount_currency', 'receive_amount_currency']
  hideLabelProps: Array<string> = ['counter']
  numberFormatProps: Array<string> = ['base_amount', 'deposit_expected_amount_fee_included', 'receive_amount']
  remittanceList: Array<RemittanceGroupDetail> = []
  isRegisterDisabled = true
  fxTimer = 0

  NumberOfDigit: { [key: string]: Array<string> } = {
    '2': ['usd', 'jpy', 'eur', 'cny', 'gbp', 'aud', 'cad', 'try', 'sgd', 'myr', 'hkd'],
    '3': ['thb', 'php', 'khr'],
    '4': ['idr', 'inr', 'pkr', 'bdt', 'npr', 'vnd', 'lkr']
  }

  orderOfRates: { [key: string]: number } = {
    usd: 1,
    jpy: 2,
    eur: 3,
    cny: 4,
    hkd: 5,
    gbp: 6,
    aud: 7,
    cad: 8,
    try: 9,
    thb: 10,
    sgd: 11,
    myr: 12,
    idr: 13,
    inr: 14,
    pkr: 15,
    bdt: 16,
    php: 17,
    khr: 18,
    npr: 19,
    vnd: 20,
    lkr: 21
  }

  ratesDetail() {
    Decimal.set({ precision: 32 })
    const ratesDetail: Array<Dictionary<any>> = []
    const unitHundredCurrency = ['vnd', 'idr', 'jpy', 'khr']
    const krwRates: Dictionary<any> = Object.assign({}, this.krwRates)
    let usdDetail: Dictionary<any> = {}
    Object.keys(krwRates).forEach(rateName => {
      if (!['id', '@type'].includes(rateName)) {
        const currency = rateName.split('_')[0]
        const name = this.$t(`currency.${currency}`)
        if (typeof name === 'string' && name.includes('currency')) return
        const isUnitHundredCurrency: boolean = unitHundredCurrency.includes(currency)
        const baseAmount: string = isUnitHundredCurrency ? '100' : '1'
        const baseCurrency: string = `${baseAmount} ${currency.toUpperCase()}`
        const operator: string = ' = '
        const bigRateAmount = new Decimal(parseFloat(krwRates[rateName]))
        const rateAmountBeforeDowned: string = isUnitHundredCurrency
          ? bigRateAmount.mul(100).toString()
          : krwRates[rateName]
        let numberOfDigit: number = 0
        for (const [key, value] of Object.entries(this.NumberOfDigit)) {
          if (value.find(currencyString => currencyString === currency)) numberOfDigit = Number(key)
        }
        const rateAmount: string = Number(rateAmountBeforeDowned).toFixed(numberOfDigit)
        if (name === this.$t('currency.usd')) {
          usdDetail = Object.assign({}, { name, baseCurrency, operator, rateAmount })
          return
        }
        ratesDetail.push({ name, baseCurrency, operator, rateAmount, currency })
      }
    })
    ratesDetail.sort((rateA, rateB) => {
      return this.orderOfRates[rateA.currency] < this.orderOfRates[rateB.currency]
        ? -1
        : this.orderOfRates[rateA.currency] > this.orderOfRates[rateB.currency]
        ? 1
        : 0
    })
    ratesDetail.splice(0, 0, usdDetail)
    return ratesDetail
  }

  get remittanceAmount(): string {
    return formattersMap.number(this.presentation.totalAmount)
  }

  get remittanceCount(): number {
    return this.presentation.totalCount
  }

  get remittanceCommission(): number {
    return formattersMap.number(this.presentation.totalCommission)
  }

  getCountryLabel(country: string) {
    return Utils.getCountryLabel(country)
  }

  viewRates(): void {
    this.ratesDialog = true
  }

  async register(): Promise<void> {
    await this.refreshTableData()
    this.isRegisterDisabled = true
    const responseEnum = await this.presentation.createRemittanceGroup()
    if (responseEnum === CreateRemittanceGroupResponseEnum.FAIL) {
      this.isRegisterDisabled = false
      return
    }
    if (responseEnum === CreateRemittanceGroupResponseEnum.ALREADY_EXIST) {
      this.isRegisterDisabled = false
      await this.$router.push('/history')
      return
    }
    this.$message(`${this.$t('notification.transfer_requested')}`)
    this.setCreatedRemittance({ amount: this.presentation.totalAmount, corp_id: this.presentation.corpId })
    this.isRegisterDisabled = false
    await this.$router.replace('registered')
  }

  goBackToUploadInvoice() {
    const message = `${this.$t('commons.must_invoice_5000usd')}`
    this.$alert(message, {
      showCancelButton: true,
      showConfirmButton: false,
      dangerouslyUseHTMLString: true,
      cancelButtonText: `${this.$t('commons.close')}`,
      center: true
    })
    return this.$router.back()
  }

  setRateTime(): void {
    const fxTaskId = this.krwRates.id
    this.ratesTime = `${fxTaskId.substr(0, 4)}-${fxTaskId.substr(4, 2)}-${fxTaskId.substr(6, 2)} ${fxTaskId.substr(
      8,
      2
    )}:${fxTaskId.substr(10, 2)}`
  }

  private async setFxRates(): Promise<void> {
    this.krwRates = await this.presentation.getKrwFxRates()
  }

  refreshTableDataMessage() {
    const rateUpdateMessage = this.$t('commons.recent_currency_update') as string
    this.$message.success(rateUpdateMessage)
  }

  async refreshTableData() {
    this.hasTableData = false
    await this.presentation.refreshRemittanceGroupTableData()
    await this.setFxRates()
    this.setRateTime()
    this.tableData = this.presentation.getTableData()
    this.hasTableData = true
  }

  startFxTimer() {
    clearTimeout(this.fxTimer)
    this.fxTimer = setTimeout(() => {
      this.refreshTableData()
      this.refreshTableDataMessage()
      this.startFxTimer()
    }, 1000 * 60 * 10)
  }

  destroyed() {
    clearTimeout(this.fxTimer)
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    clearTimeout(this.fxTimer)
    next()
  }

  async created() {
    if (!Object.keys(this.getRegisterTransfers).length) {
      this.$alert(`${this.$t('notification.wrong_approach')}`, 'WARNING', {
        type: 'warning',
        center: true
      })
      return this.$router.push('/transfer/selectRecipients')
    }
    const remittanceGroups: Array<RemittanceGroupDetail> = JSON.parse(
      JSON.stringify((await this.getAnalogueConfirmedRemittanceGroups) || [])
    )
    const isFromAnalogueApply: boolean = !!remittanceGroups.length
    if (!isFromAnalogueApply) {
      this.presentation.initializeWithTransferRow(this.getRegisterTransfers)
      const hasAnalogue = await this.presentation.hasAnalogue()
      if (hasAnalogue) {
        this.setAnalogueRemittanceGroups(this.presentation.remittanceGroups)
        await this.$router.replace('/transfer/analogue')
        return
      }
      const isInvoiceRequired = this.presentation.hasInvoiceRequired()
      if (isInvoiceRequired) {
        return this.goBackToUploadInvoice()
      }
    } else {
      this.presentation.initializeWithRemittanceGroup(remittanceGroups)
      await this.setAnalogueConfirmedRemittanceGroups([])
    }
    if (this.presentation.hasInvalidRemittanceGroup()) return this.$router.back()
    await this.setFxRates()
    this.setRateTime()
    this.tableData = this.presentation.getTableData()
    this.hasTableData = true
    this.isRegisterDisabled = false
    this.startFxTimer()
  }
}
