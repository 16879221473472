import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'

export interface CreateRemittanceGroupParam {
  corp_id: string;
  corp_member_id: number;
  symbol: string;
  total_amount: number;
  fx_task_id: string;
  remittance_list: Array<RemittanceGroupDetail>
}

export interface CreateRemittanceGroup extends IApiRequest {
  getInstance (): CreateRemittanceGroup;
  request (data: CreateRemittanceGroupParam): Promise<any>;
}

export class CreateRemittanceGroup implements CreateRemittanceGroup {
  private static instance: CreateRemittanceGroup
  private constructor () {}
  public static getInstance (): CreateRemittanceGroup {
    if (!this.instance) this.instance = new CreateRemittanceGroup()
    return this.instance
  }

  async request (data: CreateRemittanceGroupParam): Promise<any> {
    const requestOption: RequestOption = {
      url: 'remittance/group',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response
  }
}
