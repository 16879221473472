import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'
import { IRemittanceBase } from '@/gateway/remittanceBase/model/IRemittanceBase'
import { CurrencyEnum } from '@/enums/CurrencyEnum'

export interface IRemittanceEntity {
  defaultRemittanceGroups: Array<RemittanceGroupDetail>;
  setRemittanceGroups (remittanceGroups: Array<RemittanceGroupDetail>): void;
  setAnalogueFields (isAnalogue: boolean, isKorCorp: boolean, remittanceBases: Array<IRemittanceBase>): void;
  filterAnalogueInRemittanceGroups (): Array<RemittanceGroupDetail>;
}

export class RemittanceEntity implements IRemittanceEntity {
  private remittanceGroups: Array<RemittanceGroupDetail> = []
  private isAnalogueCorp: boolean = false
  private isKorCorp: boolean = true
  private remittanceBases: Array<IRemittanceBase> = []
  public defaultRemittanceGroups: Array<RemittanceGroupDetail> = []

  public setRemittanceGroups (remittanceGroups: Array<RemittanceGroupDetail>): void {
    this.remittanceGroups = remittanceGroups
  }

  public setAnalogueFields (isAnalogue: boolean, isKorCorp: boolean, remittanceBases: Array<IRemittanceBase>): void {
    this.isAnalogueCorp = isAnalogue
    this.isKorCorp = isKorCorp
    this.remittanceBases = remittanceBases
  }

  private isSupportedBaseCurrency (remittanceGroup: RemittanceGroupDetail, remittanceBase?: IRemittanceBase): boolean {
    const remittanceCurrency = remittanceGroup.base_amount.currency as keyof typeof CurrencyEnum
    const supportedCurrencies = remittanceBase ? [...remittanceBase.supported_currency, 'USD', 'KRW'] : ['USD']
    return supportedCurrencies.includes(remittanceCurrency)
  }

  private isSupportedReceiveCurrency (remittanceGroup: RemittanceGroupDetail, remittanceBase?: IRemittanceBase): boolean {
    const receiveCurrency = remittanceGroup.recipient.currency
    const supportedCurrencies = remittanceBase ? remittanceBase.supported_currency : []
    return supportedCurrencies.includes(receiveCurrency)
  }

  private isOverAmount (remittanceGroup: RemittanceGroupDetail, remittanceBase: IRemittanceBase): boolean {
    return remittanceGroup.usd_amount >= remittanceBase.limit_amount
  }

  public filterAnalogueInRemittanceGroups (): Array<RemittanceGroupDetail> {
    const defaultRemittanceGroups: Array<RemittanceGroupDetail> = []
    const analogueRemittanceGroups: Array<RemittanceGroupDetail> = []
    const setAnalogueRemittance = (remittance: RemittanceGroupDetail): void => {
      remittance.analogue = true
      analogueRemittanceGroups.push(remittance)
    }
    this.remittanceGroups.forEach(remittance => {
      if (!this.isKorCorp) return setAnalogueRemittance(remittance)
      const remittanceBase = this.remittanceBases.find(remittanceBase => remittanceBase.country === remittance.recipient.country)
      const isSupportedBaseCurrency = this.isSupportedBaseCurrency(remittance, remittanceBase)
      const isSupportedReceiveCurrency = this.isSupportedReceiveCurrency(remittance, remittanceBase)
      if (this.isAnalogueCorp) {
        const isSupportedCountry = !!remittanceBase
        const isUsdReceiveCurrency = remittance.recipient.currency === 'USD'
        const isAnalogueCurrency = isUsdReceiveCurrency && !isSupportedReceiveCurrency
        if (!isSupportedCountry || !isSupportedBaseCurrency || isAnalogueCurrency) return setAnalogueRemittance(remittance)
      }
      if (!remittanceBase) return
      if (!isSupportedBaseCurrency || !isSupportedReceiveCurrency) return
      const isOverAmount = this.isOverAmount(remittance, remittanceBase)
      if (isOverAmount) return setAnalogueRemittance(remittance)
      defaultRemittanceGroups.push(remittance)
    })
    this.defaultRemittanceGroups = defaultRemittanceGroups
    return analogueRemittanceGroups
  }
}
