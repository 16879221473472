import { IRemittanceUseCase, RemittanceUseCase } from '@/usecase/remittance/RemittanceUseCase'
import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'
import { Remittance } from '@/presentation/remittance/model/Remittance'
import { SummaryRow } from '@/presentation/remittance/model/SummaryRow'
import { Decimal } from 'decimal.js'
import { KrwFxRates } from '@/gateway/fxRates/KrwFxRates'
import { CreateRemittanceGroupResponseEnum } from '@/usecase/remittance/model/CreateRemittanceGroupResponseEnum'

export interface IRegisterRemittancePresentation {
  totalAmount: number
  totalCommission: number
  totalCount: number
  remittanceGroups: Array<RemittanceGroupDetail>
  corpId: string
  hasAnalogue(): Promise<boolean>
  hasInvoiceRequired(): boolean
  hasInvalidRemittanceGroup(): boolean
  getKrwFxRates(): Promise<KrwFxRates>
  createRemittanceGroup(): Promise<CreateRemittanceGroupResponseEnum>
  refreshRemittanceGroupTableData(): Promise<void>
  getTableData(): Dictionary<Array<Remittance | SummaryRow>>
  initializeWithTransferRow(remittances: Array<TransferRow>): void
  initializeWithRemittanceGroup(remittanceGroups: Array<RemittanceGroupDetail>): void
}

export class RegisterRemittancePresentation implements IRegisterRemittancePresentation {
  public totalAmount = 0
  public totalCommission = 0
  public totalCount = 0
  public transferRows: Array<TransferRow> = []
  private remittanceUseCase: IRemittanceUseCase
  constructor() {
    this.remittanceUseCase = new RemittanceUseCase()
  }

  get remittanceGroups(): Array<RemittanceGroupDetail> {
    return this.remittanceUseCase.remittanceGroups
  }

  get corpId(): string {
    return this.remittanceUseCase.corpId
  }

  async hasAnalogue(): Promise<boolean> {
    const hasAnalogue = await this.remittanceUseCase.checkAnalogueRemittances(this.transferRows)
    return hasAnalogue
  }

  hasInvoiceRequired(): boolean {
    return this.remittanceUseCase.hasInvoiceRequired()
  }

  hasInvalidRemittanceGroup(): boolean {
    return this.remittanceUseCase.hasInvalidRemittanceGroup()
  }

  async getKrwFxRates(): Promise<KrwFxRates> {
    const krwRates = await this.remittanceUseCase.getKrwFxRates()
    return krwRates
  }

  async createRemittanceGroup(): Promise<CreateRemittanceGroupResponseEnum> {
    const response = await this.remittanceUseCase.createRemittanceGroup(this.totalAmount)
    return response
  }

  private addTotalSummary(
    remittanceTableData: Dictionary<Array<Remittance>>
  ): Dictionary<Array<Remittance | SummaryRow>> {
    const tableData: Dictionary<Array<Remittance | SummaryRow>> = {}
    Object.keys(remittanceTableData).forEach(country => {
      const currencyWithBaseAmount: Dictionary<any> = {}
      const currencyWithSendAmount: Dictionary<any> = {}
      const remittances = remittanceTableData[country]
      remittances.forEach((row: Remittance) => {
        if (!currencyWithBaseAmount[row.base_amount_currency]) currencyWithBaseAmount[row.base_amount_currency] = 0
        if (!currencyWithSendAmount[row.send_amount_currency]) currencyWithSendAmount[row.send_amount_currency] = 0
        currencyWithBaseAmount[row.base_amount_currency] += row.base_amount
        currencyWithSendAmount[row.send_amount_currency] += row.deposit_expected_amount_fee_included
      })
      const summaryRow: SummaryRow = {
        counter: { type: 'counter', value: remittanceTableData[country].length },
        base_amount: { type: 'summary', value: currencyWithBaseAmount },
        deposit_expected_amount_fee_included: { type: 'summary', value: currencyWithSendAmount },
        receive_amount: ''
      }
      tableData[country] = remittances
      tableData[country].push(summaryRow)
    })
    return tableData
  }

  async refreshRemittanceGroupTableData(): Promise<void> {
    this.totalAmount = 0
    this.totalCommission = 0
    this.totalCount = 0
    await this.remittanceUseCase.refreshRemittanceGroup()
  }

  getTableData(): Dictionary<Array<Remittance | SummaryRow>> {
    const remittanceTableData: Dictionary<Array<Remittance>> = {}
    let counter = 1
    this.remittanceGroups.forEach(remittanceGroup => {
      const recipient = remittanceGroup.recipient
      const country = recipient.country
      if (!remittanceTableData[country]) remittanceTableData[country] = []
      const fullName = `${recipient.first_name}${recipient.middle_name ?? ' '}${recipient.last_name ?? ''}`
      const invoice = remittanceGroup.file
        ? { name: remittanceGroup.file_name || '', value: remittanceGroup.file }
        : { name: '', value: '' }
      const baseAmountCurrency = remittanceGroup.base_amount.currency
      const sendAmountCurrency = remittanceGroup.send_amount.currency
      const version = remittanceGroup.version
      const commission = version === 4 ? remittanceGroup.fixed_commission : remittanceGroup.sender_commission
      const remittance: Remittance = {
        counter: counter++,
        id: recipient.id,
        corp_pid: recipient.corp_pid,
        full_name: fullName,
        base_amount: remittanceGroup.base_amount.balance,
        base_amount_currency: baseAmountCurrency,
        deposit_expected_amount_fee_included: remittanceGroup.send_amount.balance,
        send_amount_currency: sendAmountCurrency,
        receive_amount: remittanceGroup.receive_amount.balance,
        receive_amount_currency: remittanceGroup.receive_amount.currency,
        invoice,
        comment: remittanceGroup.comment || remittanceGroup.analogue_comment
      }
      const bigTotalAmount: Decimal = new Decimal(this.totalAmount)
      const bigTotalCommission: Decimal = new Decimal(this.totalCommission)
      this.totalAmount = bigTotalAmount.plus(remittance.deposit_expected_amount_fee_included).toNumber()
      this.totalCommission = bigTotalCommission.plus(commission).toNumber()
      this.totalCount++
      remittanceTableData[country].push(remittance)
    })
    return this.addTotalSummary(remittanceTableData)
  }

  initializeWithTransferRow(transferRows: Array<TransferRow>) {
    this.transferRows = transferRows
  }

  initializeWithRemittanceGroup(remittanceGroups: Array<RemittanceGroupDetail>) {
    this.remittanceUseCase.remittanceGroups = remittanceGroups
  }
}
