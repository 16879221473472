import { IRemittanceEntity, RemittanceEntity } from '@/entity/remittance/RemittanceEntity'
import { GetCorpInformation } from '@/gateway/commons/GetCorpInformation'
import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import { GetRemittanceBase } from '@/gateway/remittanceBase/GetRemittanceBase'
import { RemittanceGroupDetail } from '@/models/remittance/RemittanceGroupDetail'
import Recipient from '@/models/recipient/Recipient'
import { GetRecipients, GetRecipientsParam } from '@/gateway/recipient/GetRecipients'
import MemberInfo from '@/models/MemberInfo'
import { GetFxRates } from '@/gateway/fxRates/GetFxRates'
import { FxRates } from '@/gateway/fxRates/FxRates'
import { CorpInformation } from '@/gateway/commons/model/CorpInformation'
import { REQUIRED_INVOICE_USD_AMOUNT } from '@/data/TransferConstants'
import { KrwFxRates } from '@/gateway/fxRates/KrwFxRates'
import { CreateRemittanceGroup, CreateRemittanceGroupParam } from '@/gateway/remittance/CreateRemittanceGroup'
import { CreateRemittanceGroupResponseEnum } from '@/usecase/remittance/model/CreateRemittanceGroupResponseEnum'
import store from '@/store'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import { CalculatorSourceType, Calculator, ICalculatorSource } from '@/lib/calculator/Calculator'

export interface IRemittanceUseCase {
  remittanceGroups: Array<RemittanceGroupDetail>
  corpId: string
  getFxRates(): Promise<FxRates>
  getKrwFxRates(): Promise<KrwFxRates>
  createRemittanceGroup(totalAmount: number): Promise<CreateRemittanceGroupResponseEnum>
  refreshRemittanceGroup(): Promise<void>
  checkAnalogueRemittances(remittances: Array<TransferRow>): Promise<boolean>
  hasInvoiceRequired(): boolean
  hasInvalidRemittanceGroup(): boolean
}

export class RemittanceUseCase implements IRemittanceUseCase {
  private transferRows: Array<TransferRow> = []
  private recipients: Array<Recipient> = []
  private remittanceEntity: IRemittanceEntity
  private memberInfoField!: MemberInfo
  private rates!: FxRates
  private krwRates!: KrwFxRates
  private corpInfo!: CorpInformation
  public remittanceGroups: Array<RemittanceGroupDetail> = []
  constructor() {
    this.remittanceEntity = new RemittanceEntity()
  }

  get corpId(): string {
    return this.memberInfo.corp_id
  }

  private get memberInfo(): MemberInfo {
    if (!this.memberInfoField) this.memberInfoField = MemberInfoGateway.getMemberInfo()
    return this.memberInfoField
  }

  private async getCorpInfo(): Promise<CorpInformation> {
    if (!this.corpInfo) this.corpInfo = await GetCorpInformation.get({ corp_id: this.memberInfo.corp_id })
    return this.corpInfo
  }

  private async refreshRates() {
    this.rates = await GetFxRates.get()
    this.krwRates = await GetFxRates.getKrwMidRatesV3()
  }

  async getFxRates(): Promise<FxRates> {
    if (!this.rates) this.rates = await GetFxRates.get()
    return this.rates
  }

  async getKrwFxRates(): Promise<KrwFxRates> {
    this.krwRates = await GetFxRates.getKrwAppliedRatesV4()
    return this.krwRates
  }

  async createRemittanceGroup(totalAmount: number): Promise<CreateRemittanceGroupResponseEnum> {
    const param: CreateRemittanceGroupParam = {
      corp_id: this.memberInfo.corp_id,
      corp_member_id: this.memberInfo.member_id,
      symbol: (await this.getCorpInfo()).corp_symbol,
      total_amount: totalAmount,
      fx_task_id: this.rates.id,
      remittance_list: this.remittanceGroups
    }
    const response = await CreateRemittanceGroup.getInstance().request(param)
    const isSuccessResponse: boolean = response?.code === 200
    if (isSuccessResponse) return CreateRemittanceGroupResponseEnum.SUCCESS
    if (response && !response.code) return CreateRemittanceGroupResponseEnum.ALREADY_EXIST
    return CreateRemittanceGroupResponseEnum.FAIL
  }

  private async reCalculateRemittanceGroup() {
    await this.getCorpInfo()
    this.remittanceGroups = this.remittanceGroups.map(remittanceGroup => {
      const calculatorSource: ICalculatorSource = {
        type: CalculatorSourceType.REMITTANCE_GROUP,
        remittanceGroupDetail: remittanceGroup,
        corpInfo: this.corpInfo
      }
      const calculator = new Calculator(this.rates)
      calculator.setSource(calculatorSource)
      const calculateResult = calculator.calculate()
      remittanceGroup.send_amount.balance = calculateResult.sendAmount
      remittanceGroup.receive_amount.balance = calculateResult.receiveAmount
      remittanceGroup.base_amount.balance = calculateResult.baseAmount
      remittanceGroup.sender_commission = calculateResult.commission
      remittanceGroup.fixed_commission = calculateResult.fixedCommission
      remittanceGroup.total_commission = calculateResult.totalCommission
      remittanceGroup.usd_amount = calculateResult.usdAmount
      remittanceGroup.fixed_fee = calculateResult.fee.fixed
      remittanceGroup.rate_fee = calculateResult.fee.rate
      remittanceGroup.ratio = calculateResult.fee.ratio
      remittanceGroup.min_amount = calculateResult.fee.min_amount
      remittanceGroup.min_fee = calculateResult.fee.min_fee
      remittanceGroup.fee_currency = calculateResult.fee.fee_currency
      remittanceGroup.version = calculateResult.fee.version
      return remittanceGroup
    })
  }

  public async refreshRemittanceGroup(): Promise<void> {
    await this.refreshRates()
    await this.reCalculateRemittanceGroup()
  }

  private convertToRemittanceGroups(): Array<RemittanceGroupDetail> {
    const remittanceGroups: Array<RemittanceGroupDetail> = []
    this.transferRows.forEach(transferRow => {
      const recipient = this.recipients.find(recipient => recipient.corp_pid === transferRow.corps_id)
      if (!recipient) return
      const calculatorSource: ICalculatorSource = {
        type: CalculatorSourceType.REMITTANCE,
        transferRow,
        corpInfo: this.corpInfo,
        recipient
      }
      const calculator = new Calculator(this.rates)
      calculator.setSource(calculatorSource)
      const calculateResult = calculator.calculate()
      const remittanceGroup: RemittanceGroupDetail = {
        analogue: false,
        send_amount: {
          currency: 'KRW',
          balance: calculateResult.sendAmount
        },
        receive_amount: {
          currency: recipient.currency,
          balance: calculateResult.receiveAmount
        },
        base_amount: {
          currency: transferRow.base_currency,
          balance: calculateResult.baseAmount
        },
        sender_commission: calculateResult.commission,
        fixed_commission: calculateResult.fixedCommission,
        total_commission: calculateResult.totalCommission,
        usd_amount: calculateResult.usdAmount,
        fixed_fee: calculateResult.fee.fixed,
        rate_fee: calculateResult.fee.rate,
        ratio: calculateResult.fee.ratio,
        min_amount: calculateResult.fee.min_amount,
        min_fee: calculateResult.fee.min_fee,
        fee_currency: calculateResult.fee.fee_currency,
        file: transferRow.invoice?.value,
        file_name: transferRow.invoice?.name,
        comment: transferRow.comment,
        version: calculateResult.fee.version,
        recipient
      }
      remittanceGroups.push(remittanceGroup)
    })
    return remittanceGroups
  }

  async checkAnalogueRemittances(transferRows: Array<TransferRow>): Promise<boolean> {
    this.transferRows = transferRows
    this.corpInfo = await this.getCorpInfo()
    const isKorCorp = this.corpInfo.kor_corp
    const isAnalogueCorp = this.corpInfo.analogue
    const remittanceBases = await GetRemittanceBase.getInstance().request()
    const corpsIds = transferRows.map(transferRow => transferRow.corps_id)
    const getRecipientsParam: GetRecipientsParam = {
      corp_id: this.memberInfo.corp_id,
      corp_pids: corpsIds,
      page: 1,
      unit: corpsIds.length
    }
    const getRecipientsResponse = await GetRecipients.getInstance().request(getRecipientsParam)
    this.recipients = getRecipientsResponse.list
    this.rates = await this.getFxRates()
    this.remittanceGroups = this.convertToRemittanceGroups()
    this.remittanceEntity.setRemittanceGroups(this.remittanceGroups)
    this.remittanceEntity.setAnalogueFields(isAnalogueCorp, isKorCorp, remittanceBases)
    const analogueRemittanceGroups = this.remittanceEntity.filterAnalogueInRemittanceGroups()
    const defaultRemittanceGroups = this.remittanceEntity.defaultRemittanceGroups
    this.remittanceGroups = [...analogueRemittanceGroups, ...defaultRemittanceGroups]
    return analogueRemittanceGroups.length > 0
  }

  private setInvoiceUpdatable(corpPid: string) {
    const isManualApply = !!Object.keys(store.getters.getManualValidatedTransfers).length
    const transfers: Dictionary<Array<TransferRow>> = isManualApply
      ? store.getters.getManualValidatedTransfers
      : store.getters.getValidatedTransfers
    for (const countryWithMethod of Object.keys(transfers)) {
      const transferRow: TransferRow | undefined = transfers[countryWithMethod].find(
        transfer => transfer.corps_id === corpPid
      )
      if (transferRow) {
        transferRow.updatable = true
        break
      }
    }
    const setTransfersMethod = isManualApply ? 'setManualValidatedTransfers' : 'setValidatedTransfers'
    store.commit(setTransfersMethod, transfers)
  }

  hasInvoiceRequired(): boolean {
    return this.remittanceGroups.some(remittanceGroup => {
      const isOverLimit = remittanceGroup.usd_amount >= REQUIRED_INVOICE_USD_AMOUNT
      const hasInvoice = !!remittanceGroup.file
      const isRequired = isOverLimit && !hasInvoice
      if (isRequired) this.setInvoiceUpdatable(remittanceGroup.recipient.corp_pid)
      return isRequired
    })
  }

  hasInvalidRemittanceGroup(): boolean {
    return this.remittanceGroups.some(remittance => !remittance.send_amount)
  }
}
